document.addEventListener('turbolinks:load', function () {
    $('a[href="' + location.pathname + '"]').closest('li').addClass('active');
    if (location.pathname.includes('categories')) {
        document.querySelector('#dropdownProductionButton').classList.add('font-weight-bold')
    }

    // Mobile menu
    let overlay = document.querySelector('#mobileMenuOverlay')
    document.querySelector('#mobileMenuOpen').addEventListener('click', function () {
        overlay.classList.remove('d-none')
        overlay.classList.remove('slide-out')
        overlay.classList.add('slide-in')
    })

    document.querySelector('#mobileMenuClose').addEventListener('click', function () {
        overlay.classList.add('slide-out')
        overlay.classList.remove('slide-in')
    })

    document.querySelector('#mobileDropdownProductionToggle').addEventListener('click', function () {
        document.querySelector('#mobileProductionDropdown').classList.toggle('d-none')
    })

    document.querySelector('.go-up-button img').addEventListener('click', (e) => {
        document.querySelector('body').scrollIntoView();
    })
})